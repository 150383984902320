import React from 'react'
import Link from '../components/Link'
import styled from 'react-emotion'
import HalfSizeImage from '../components/HalfSizeImage'
import Image from '../components/Image'
import { One, Row, Three } from '../components/Grid'
import Button from '../components/Button'
import Caption from '../components/Caption'
import SEO from '../components/SEO'
import { H1, H2, H4, Section } from '../components/Style'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/jobs",
      "name": "Jobs"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/jobs"
        title="Join the front-end experts at 500Tech"
        description="We’re looking for exceptional developers to build challenging and diverse projects with latest front-end tech together with an exceptional team."
        ld={breadcrumbs}
      />

      <Section>
        <H1>We have jobs for exceptional developers</H1>
      </Section>

      <Row>
        <Three>
          <p>
            At 500Tech we believe in great development and the process of always
            improving. We work on helping you grow technically, try consult
            clients, become a public speaker, and start teaching others. Nothing
            beats working with other super smart people who are really
            passionate about technology.
          </p>

          <Image path="jobs/1.jpg" />
          <br />

          <p>
            We believe in a flat hierarchy and no needless managers. Beyond
            being a great coder, you will be a product and project manager. Our
            developers work directly with clients, plan and monitor their work.
            You’ll need to be responsible, autonomous and pragmatic.
          </p>
        </Three>
        <One>
          <div className="smaller">
            <H4>Requirements</H4>
            <p>
              Regardless of your experience, you must be an exceptional coder.
              Knowing JavaScript and Web is a big plus, knowing React or Angular
              is a nice bonus.
            </p>

            <H4>Hiring process</H4>
            <p>
              After a short phone call we may invite you to visit us at our
              office. You will sit with one of the technical people and try to
              write some complicated code together. There are no whiteboard
              challenges or homework, unless you really want it.
            </p>
          </div>

          <ApplyButton to="https://www.comeet.com/jobs/500tech/47.000">Get in touch</ApplyButton>
        </One>
      </Row>

      <Row>
        <Three>
          <H2>Challenging, diverse projects</H2>
          <p>
            We don’t build websites, we build complex web
            applications in different domains with very different feature sets.
            Our goal is to stay top experts in latest front-end tech and help
            companies build better software. Our developers are creative and
            curious when confronted with a hard technical problem.
          </p>
        </Three>
      </Row>

      <Row>
        <Three>
          <Row scrollable>
            <One>
              <Link to="/projects/phytech" noUnderline innerHighlight>
                <Image path="projects/phytech/phytech-icon.jpg" />
              </Link>

              <Caption>
                <Link to="/projects/phytech" white className="inner-link">
                  Phytech
                </Link>{' '}
                helps farmers monitor fields online
              </Caption>
            </One>

            <One>
              <Link to="/projects/jfrog" noUnderline innerHighlight>
                <Image path="projects/jfrog/jfrog-icon.jpg" />
              </Link>

              <Caption>
                <Link to="/projects/jfrog" white className="inner-link">
                  jFrog
                </Link>{' '}
                provides world-class infrastructure for software management and
                distribution
              </Caption>
            </One>

            <One>
              <Link to="/projects/proggio" noUnderline innerHighlight>
                <Image path="projects/proggio/proggio-icon.jpg" />
              </Link>

              <Caption>
                <Link to="/projects/proggio" white className="inner-link">
                  Proggio
                </Link>{' '}
                develops a new approach to project management
              </Caption>
            </One>

            <One>
              <Link to="/projects/unpakt" noUnderline innerHighlight>
                <Image path="projects/unpakt/unpakt-icon.jpg" />
              </Link>

              <Caption>
                <Link to="/projects/unpakt" white className="inner-link">
                  Unpakt
                </Link>{' '}
                helps people move by finding the best available mover
              </Caption>
            </One>
          </Row>
        </Three>
      </Row>

      <Row>
        <Three>
          <H2>Code as a product</H2>
          <p>
            Most of our projects are about building a product from the ground up
            to a fully production application. However, not only the software,
            but the code itself is our product; other people will work with it
            after us, so we have to maintain high quality, testability, and
            extensibility.
          </p>
          <Image path="jobs/2.jpg" />
          <br />
        </Three>
      </Row>

      <Row>
        <Three>
          <H2>Cutting edge technology</H2>
          <p>We use the latest versions of advanced frameworks:</p>
          <Technologies>
            <Tech>
              <HalfSizeImage path="jobs/tech-react.png" />
              <TechTitle>
                React
              </TechTitle>
            </Tech>
            <Tech>
              <HalfSizeImage path="jobs/tech-angular.png" />
              <TechTitle>Angular</TechTitle>
            </Tech>
            <Tech>
              <HalfSizeImage path="jobs/tech-webpack.png" />
              <TechTitle>Webpack</TechTitle>
            </Tech>
            <Tech>
              <HalfSizeImage path="jobs/tech-jest.png" />
              <TechTitle>Jest</TechTitle>
            </Tech>
            <Tech>
              <HalfSizeImage path="jobs/tech-babel.png" />
              <TechTitle>Babel</TechTitle>
            </Tech>
          </Technologies>

          <p>
            We don’t accept projects based old technologies like jQuery,
            Backbone, or AngularJS and develop our own development tools
            to stay productive and effective.
          </p>
        </Three>
      </Row>

      <Row>
        <Three>
          <H2>Community</H2>
          <p>
            We organize meetups and conferences. Many of us are also frequent
            public speakers. This helps stay on top, build new connections, and
            learn and develop new best practices. We support and encourage
            public speaking and social engagement, starting with weekly{' '}
            <nobr>in-company</nobr>
            {' '}lectures.
          </p>
          <Image path="jobs/3.jpg" />
          <Caption>
            Ilya Gelman talking React at a meetup in Google Campus, Tel Aviv
          </Caption>
        </Three>
      </Row>

      <Row>
        <Three>
          <H2>Life balance</H2>
          <p>
            We are not a startup, so we don't work overtime. However,
            many of us do programming at home just for fun. But we also care about
            you having a life beside work and programming.
          </p>
          <Image path="jobs/4.jpg" />
          <br />

          <p>
            Among our hobbies are snowboarding, surfing, embroidery, urban
            gardening, brewing beer, west coast swing, remote controlled
            aircrafts, and cooking.
          </p>
        </Three>
      </Row>
      <Row>
        <ApplyButton to="https://www.comeet.com/jobs/500tech/47.000">Get in touch</ApplyButton>
      </Row>
    </Layout>
  )
}

const Technologies = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`

const Tech = styled.div`
  margin: 0 15px 28px 0;
  width: 13%;

  @media only screen and (max-width: 1023px) {
    margin: 0 15px 28px 0;
    width: 28%;
  }
`

const TechTitle = styled.div`
  font-size: 0.8em;
  line-height: 1.2;
`

const ApplyButton = styled(Button.withComponent(Link))`
  color: white !important;
  border-bottom: none !important;
`
